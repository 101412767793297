<template>
  <header
    class="header"
    :class="{
      'opened-mobile': showMobileMenu,
      'first-slide': $route.name === 'Home' && activeSlide === 0,
    }"
  >
    <div class="main">
      <a :href="homePath" class="logo-wrapper">
        <LogoIcon :dark="blackTheme" />
      </a>
    </div>

    <BurgerIcon
      v-if="width < 1201"
      :dark="showMobileMenu || width < 1201"
      @clicked="showMobileMenu = !showMobileMenu"
      class="burger-icon"
    />

    <search-comp
      :dark="showMobileMenu || blackTheme"
      class="search-comp"
      v-if="width > 1200 || showMobileMenu"
    />

    <nav
      class="nav"
      v-if="width > 1200 || showMobileMenu"
      :class="{ 'dark-nav': blackTheme }"
      @click="showMobileMenu = false"
    >
      <!--      <LanguageSwitcher v-if="showLanguages" :options="['ru', 'en', 'it']" @changeLang="changeLang" :blackTheme="blackTheme"-->
      <!--      />-->

      <!-- Создаем главные элементы в навигации, которые будут содержать свои внутренние менюшки -->

      <p
        v-for="(link, index) in $tm('nav')"
        :key="index"
        class="nav-link main-link"
        :class="{
          'active-route': checkRouteActive(link),
          'home-route': $rt(link.name) === 'Структура ПГ КОНАР',
        }"
        :style="{
          display: isMenuItemShow(link) ? '' : 'none'
        }"
      >
        <component
          :is="$rt(link.routeName) ? 'router-link' : 'p'"
          :to="
            $rt(link.routeName)
              ? $rt(link.routeKey)
                ? Tr.i18nRoute({
                    name: $rt(link.routeName),
                    params: { [$rt(link.routeKey)]: $rt(link.routeValue) },
                  })
                : Tr.i18nRoute({ name: $rt(link.routeName) })
              : {}
          "
        >
          <!-- Имя элемента навигации  -->
          <p
            class="name"
            @click="
              ($ev) => {
                $ev.stopPropagation();
                return link.innerLinks?.length
                  ? openInnerMenu($ev, $rt(link.name))
                  : null
              }
            "
          >
            {{ $rt(link.name) }}
            <span class="icon" v-if="link.innerLinks?.length"></span>
          </p>

          <!-- Внутренние элементы навигации  -->
          <div
            class="inner-links"
            v-if="shownInnerName === $rt(link.name) && link.innerLinks?.length"
          >
            <div class="inner-header center">
              <h2 class="title">{{ $rt(link.name) }}</h2>
              <div class="close-icon" @click="closeInnerMenu"></div>
            </div>
            <div class="nav-btns">
              <component
                :is="$rt(innerLink.routeName) ? 'router-link' : 'p'"
                class="link"
                :class="{
                  'link-opened':
                    shownInnerInnerName === $rt(innerLink.name) &&
                    innerLink.innerLinks?.length,
                  'active-route': $route.name === $rt(innerLink.routeName),
                }"
                v-for="(innerLink, innerIdx) in $tm(`nav[${index}].innerLinks`)"
                :key="innerIdx"
                :to="
                  $rt(innerLink.routeName)
                    ? $rt(innerLink.routeKey)
                      ? Tr.i18nRoute({
                          name: $rt(innerLink.routeName),
                          params: {
                            [$rt(innerLink.routeKey)]: $rt(innerLink.routeValue),
                          },
                        })
                      : Tr.i18nRoute({ name: $rt(innerLink.routeName) })
                    : {}
                "
              >
                <!-- Имя внутреннего элемента навигации  -->
                <p class="name" @click="($ev) => handleClick($ev, innerLink)">
                  {{ $rt(innerLink.name) }}
                  <span class="icon" v-if="innerLink.innerLinks?.length"></span>
                </p>

                <!-- Последняя вложенность  -->
                <div
                  class="inner-inner-links"
                  :class="{
                    'inner-inner-links--opened':
                      shownInnerInnerName === $rt(innerLink.name) &&
                      innerLink.innerLinks?.length,
                  }"
                  v-if="innerLink.innerLinks?.length"
                >
                  <router-link
                    v-for="(innerInnerLink, innerInnerIdx) in $tm(
                      `nav[${index}].innerLinks[${innerIdx}].innerLinks`
                    )"
                    class="inner-link"
                    :to="
                      $rt(innerInnerLink.routeName)
                        ? $rt(innerInnerLink.routeKey)
                          ? Tr.i18nRoute({
                              name: $rt(innerInnerLink.routeName),
                              params: {
                                [$rt(innerInnerLink.routeKey)]: $rt(
                                  innerInnerLink.routeValue
                                ),
                              },
                            })
                          : Tr.i18nRoute({ name: $rt(innerInnerLink.routeName) })
                        : {}
                    "
                    :key="innerInnerIdx"
                  >
                    <p class="name">{{ $rt(innerInnerLink.name) }}</p>
                  </router-link>
                </div>
              </component>
              <!-- Специальный тэг для неликвидов    -->
              <p
                class="link"
                v-if="$rt(link.name) === 'Продукция' && $i18n.locale === 'ru'"
              >
                <span class="name" @click="openLink(illuquidFile)"
                  >Неликвиды</span
                >
              </p>
            </div>
          </div>
        </component>
      </p>
    </nav>
    <div
      class="overlay"
      v-if="shownInnerName"
      @click="shownInnerName = null"
    ></div>
  </header>
</template>

<script>
import LogoIcon from "./icons/LogoIcon.vue";
import BurgerIcon from "./icons/BurgerIcon.vue";
import LanguageSwitcher from "./UI/language-switcher.vue";

import Tr from "@/i18n/translation";

import { useWindowSize } from "@vueuse/core";
import SearchComp from "@/components/UI/search-comp.vue";
const { width } = useWindowSize();

export default {
  data() {
    return {
      shownInnerName: "",
      shownInnerInnerName: "",

      showMobileMenu: false,

      blackTheme: false,
      width: width,

      illuquidFile: null,
    };
  },
  methods: {
    isMenuItemShow(link) {
      if (this.$rt(link.name) === 'Фотогалерея') {
        if (
            this.$route.fullPath.includes("/stankomash")
        ) {
          return false;
        }
      }
      return true;
    },
    getCompanyStructure() {
      if (this.$route.fullPath.includes("/bvk")) {
        // needed ${i18n.locale}
        this.$i18n.setLocaleMessage(
          "ru",
          require("@/i18n/nav/bvk_nav_ru.json")
        );
        this.$i18n.setLocaleMessage(
          "en",
          require("@/i18n/nav/bvk_nav_en.json")
        );
        this.$i18n.setLocaleMessage(
          "it",
          require("@/i18n/nav/bvk_nav_it.json")
        );
      } else if (this.$route.fullPath.includes("/skt")) {
        this.$i18n.setLocaleMessage(
          "ru",
          require("@/i18n/nav/skt_nav_ru.json")
        );
      } else if (
        this.$route.fullPath.includes("/konar") &&
        !this.$route.fullPath.includes("/konar-orion")
      ) {
        this.$i18n.setLocaleMessage(
          "ru",
          require("@/i18n/nav/konar_nav_ru.json")
        );
      } else if (this.$route.fullPath.includes("/kornet")) {
        this.$i18n.setLocaleMessage(
          "ru",
          require("@/i18n/nav/kornet_nav_ru.json")
        );
      } else if (this.$route.fullPath.includes("/chimolai")) {
        this.$i18n.setLocaleMessage(
          "ru",
          require("@/i18n/nav/chimolai_nav_ru.json")
        );
      } else if (this.$route.fullPath.includes("/konar-orion")) {
        this.$i18n.setLocaleMessage(
          "ru",
          require("@/i18n/nav/orion_nav_ru.json")
        );
      } else if (this.$route.fullPath.includes("/mt")) {
        this.$i18n.setLocaleMessage("ru", require("@/i18n/nav/mt_nav_ru.json"));
      } else if (this.$route.fullPath.includes("/stankomash")) {
        this.$i18n.setLocaleMessage(
          "ru",
          require("@/i18n/nav/stankomash_nav_ru.json")
        );
      } else {
        this.$i18n.setLocaleMessage(
          "ru",
          require("@/i18n/nav/main_nav_ru.json")
        );
        this.$i18n.setLocaleMessage(
          "en",
          require("@/i18n/nav/main_nav_en.json")
        );
        this.$i18n.setLocaleMessage(
          "it",
          require("@/i18n/nav/main_nav_it.json")
        );
      }
    },

    handleClick($ev, innerLink) {
      $ev.stopPropagation();
      if (this.$rt(innerLink.routeName)) {
        return 0;
      } else {
        this.openInnerInnerMenu(null, this.$rt(innerLink.name));
      }
    },

    openInnerMenu(ev, linkName) {
      ev.stopPropagation();
      ev.preventDefault();
      if (this.shownInnerName && this.shownInnerName === linkName) {
        this.shownInnerName = "";
      } else {
        this.shownInnerName = linkName;
      }
    },
    openInnerInnerMenu(ev, linkName) {
      if (this.shownInnerInnerName && this.shownInnerInnerName === linkName) {
        this.shownInnerInnerName = null;
      } else this.shownInnerInnerName = linkName;
    },
    chooseColor() {
      if (this.$route.name === "Home" && !this.showMobileMenu) {
        if (this.width <= 1200) {
          this.blackTheme = true;
        } else {
          this.blackTheme = this.activeSlide !== 0;
        }
      } else if (this.$route.name === "Home" && this.showMobileMenu) {
        this.blackTheme = true;
      } else {
        this.blackTheme = true;
      }
    },
    async changeLang(lang) {
      this.$root.$i18n.locale = lang;
      setTimeout(async () => {
        await Tr.switchLanguage(lang);
      }, 500);
      try {
        await this.$router.replace({ params: { locale: lang } });
      } catch (err) {
        console.error(err);
      }
    },
    closeInnerMenu(ev) {
      this.openInnerMenu(ev, "");
    },
    closeMenu() {
      if (window.innerWidth > 1201) {
        this.shownInnerName = "";
        this.shownInnerInnerName = "";
      }
      this.showMobileMenu = false;
    },
    async getIlliquid() {
      this.illuquidFile = await this.$api
        .get("/file_objects?object_class=Неликвиды")
        .then((res) => res.data[0].file_name);
    },
    openLink(fileName) {
      window.open(
        `${
          process.env.BACKEND_HOST
            ? process.env.BACKEND_HOST
            : process.env.VUE_APP_BACKEND_HOST
        }/files/${fileName}`,
        "_blank"
      );
    },
    checkRouteActive(route) {
      let equelPath = route.routeName === this.$route.name;
      let equelParams = false;

      if (route.innerLinks) {
        route.innerLinks.forEach((innerLink) => {
          if (this.$route.params[innerLink.routeKey] === innerLink.routeValue) {
            if (innerLink.routeKey === "enterprise") {
              if (
                this.$route.fullPath.includes(innerLink.routeName.split("-")[1])
              ) {
                equelParams = true;
              }
            } else {
              equelParams = true;
            }
          }

          if (
            innerLink.routeName &&
            this.$route.fullPath === `/${innerLink.routeName}`
          ) {
            equelPath = true;
          }

          if (innerLink.innerLinks) {
            innerLink.innerLinks.forEach((innerInnerLink) => {
              if (
                this.$route.params[innerInnerLink.routeKey] ===
                innerInnerLink.routeValue
              ) {
                equelParams = true;
              }

              if (
                innerInnerLink.routeName &&
                this.$route.fullPath === `/${innerInnerLink.routeName}`
              ) {
                equelPath = true;
              }
            });
          }
        });
      }

      // const equelParams = this.$route.params[route.routeKey] === route.routeValue
      if (route.name === "Структура ПГ КОНАР") {
        return false;
      } else {
        return equelPath || equelParams;
      }
    },
  },

  computed: {
    currentRoute() {
      return this.$route.fullPath;
    },
    homePath() {
      if (this.$route.fullPath.includes("enterprise")) {
        if (this.$route.fullPath.includes("stankomash")) {
          return `/enterprise/stankomash`;
        } else {
          return `/enterprise/${this.$route.params.enterprise}`;
        }
      }
      return "/";
    },
    showLanguages() {
      let companies = this.$store.state.companies.filter(
        (company) =>
          company.nameTranslit !== "cok" && company.nameTranslit !== "bvk"
      );
      let result = true;
      for (let i = 0; i < companies.length; i++) {
        if (this.$route.fullPath.includes(companies[i].nameTranslit)) {
          result = false;
        }
      }
      return result;
    },
  },
  watch: {
    currentRoute() {
      this.closeMenu();
      document.documentElement.style.overflowY = "auto";
      this.getCompanyStructure();
      this.chooseColor();
      // this.checkRouteActive()
    },
    shownInnerName(val) {
      if (val) {
        document.documentElement.style.overflowY = "hidden";
        this.$store.state.isMenuOpen = true;
      } else {
        document.documentElement.style.overflowY = "auto";
        this.$store.state.isMenuOpen = false;
      }
    },
    showMobileMenu(newVal) {
      this.chooseColor();
      if (newVal) {
        document.documentElement.style.overflowY = "hidden";
      } else {
        document.documentElement.style.overflowY = "auto";
      }
    },
  },
  setup() {
    return { Tr };
  },
  async mounted() {
    this.chooseColor();
    await this.getIlliquid();
  },
  updated() {
    this.chooseColor();
  },
  unmounted() {
    document.documentElement.style.overflowY = "auto";
  },
  created() {
    this.getCompanyStructure();
  },
  props: {
    activeSlide: Number,
  },
  components: { SearchComp, LogoIcon, LanguageSwitcher, BurgerIcon },
};
</script>

<style lang="sass" scoped>
@import '@/assets/styles/base/adaptive'
.header
  position: fixed
  background-color: rgba(0,0,0,0)
  z-index: 5
  left: 0
  top: 0
  width: 260px
  height: 100%
  border-right: 1px solid var(--gray-lines-color)
  .overlay
    position: fixed
    width: 100vw
    height: 100vh
    background-color: rgba(0,0,0,0.5)
    top: 0
    left: 259px
    z-index: -1
  .main
    align-items: center
    display: flex
    border-bottom: 1px solid var(--gray-lines-color)
  .logo-wrapper
    padding: 20px 30px
    display: block
    img
      max-height: 40px
      object-fit: contain
      height: 100%
  .search-comp
    position: unset
  .nav
    display: flex
    align-self: flex-start
    padding-top: 35px
    flex-direction: column
    .active-route
      .name
        background-color: #fff
        color: var(--text-color)
    // .inner-links .name
    //   background-color: #fff here
    .main-link:not(.active-route)
      .name
        overflow: hidden
        position: relative
        &::before
          content: ''
          background-color: #fff
          width: 8px
          height: 100%
          position: absolute
          left: -8px
          transition: .3s ease
        &:hover
          &::before
            left: 0
    .home-route
      margin-top: 30px
    .name
      cursor: pointer
      color: #fff
      font-size: 0.875rem
      font-weight: 600
      text-transform: uppercase
      display: flex
      justify-content: space-between
      align-items: center
      padding: 10px 30px
      transition: .3s ease
      .icon
        mask: url(@/assets/images/icons/menu-arrow.svg) no-repeat center
        mask-size: contain
        background-color: #fff
        width: 9px
        height: 14px
        transition: .3s ease
    .inner-links
      position: absolute
      left: 100%
      top: 0
      display: flex
      flex-direction: column
      background-color: #fff
      width: 465px
      height: 100%
      overflow-y: auto
      border-left: 1px solid var(--gray-lines-color)
      .inner-header
        height: 200px
        min-height: 200px
        position: relative
        border-bottom: 1px solid var(--gray-lines-color)
        .title
          font-size: 1.5rem
          font-weight: 600
          text-transform: uppercase
        .close-icon
          width: 20px
          height: 20px
          mask: url(@/assets/images/icons/pic-close.svg) no-repeat center
          background-color:  var(--dark-blue-color)
          mask-size: contain
          position: absolute
          top: 20px
          right: 20px
          transition: .3s ease
          cursor: pointer
          &:hover
            background-color: var(--text-color)
      .nav-btns
        display: flex
        flex-direction: column
        height: 100%
        overflow: auto
        scrollbar-gutter: stable
        .active-route
          .name
            color: #fff !important
        .link
          cursor: pointer
          border-bottom: 1px solid var(--gray-lines-color)
          transition: .3s ease-out
          position: relative
          &::before
            position: absolute
            content: ''
            left: 0
            top: 0
            width: 0
            height: 100%
            transition: .2s ease-out
            background-color: var(--text-color)
            z-index: 1
          &::after
            position: absolute
            content: ''
            left: 0
            top: 0
            width: 0
            height: 100%
            transition: .3s ease-in
            background-color: var(--text-color)
            z-index: 1
          .name
            font-size: 0.875rem
            font-weight: 600
            text-transform: uppercase
            color: #778497
            padding: 30px
            display: flex
            justify-content: space-between
            align-items: center
            transition: .3s ease
            .icon
              max-width: 16px
              min-width: 16px
              width: 100%
              height: 16px
              mask: url(@/assets/images/icons/open-accord.svg) no-repeat center
              background-color: var(--dark-blue-color)
              display: block
              mask-size: contain
              transition: .3s ease
              cursor: pointer
              margin-left: 12px
          &:hover
            &:after
              width: 10px
              transition: .07s ease-out

            .name
              color: var(--text-color)
              transition: .3s ease-out
              .icon
                background-color: var(--text-color)
          .inner-inner-links
            max-height: 0
            transition: .3s ease-out
            display: flex
            flex-direction: column
            overflow: hidden
            .name
              padding: 23px 30px
              color: var(--dark-blue-color)
              transition: .3s ease-out
          .inner-inner-links--opened
            max-height: 400px
            transition: .3s ease-out
            .inner-link
              background-color: var(--bg-gray-color)
              &:not(:last-child)
                border-bottom: 1px solid var(--gray-lines-color)
            .inner-link
              display: inline-block
              vertical-align: middle
              transform: perspective(1px) translateZ(0)
              position: relative
              transition: 0.3s color
              &:before
                content: ""
                position: absolute
                z-index: -1
                top: 0
                left: 0
                right: 0
                bottom: 0
                background: var(--gray-lines-color)
                transform: scaleY(0)
                transform-origin: 50% 100%
                transition: transform 0.3s ease-out
            .inner-link:hover, .inner-link:focus, .inner-link:active
              .name
                color: var(--text-color)
                transition: .3s ease-out
            .inner-link:hover:before, .inner-link:focus:before, .inner-link:active:before
              transform: scaleY(1)
        .link-opened
          cursor: pointer
          position: relative
          &::before
            position: absolute
            content: ''
            left: 0
            top: 0
            width: 10px
            height: 100%
            transition: .2s ease-out
            background-color: var(--text-color)
          .name .icon
            transform: rotate(45deg)

  .dark-nav
    .name
      color:  var(--text-color)
      .icon
        background-color: var(--text-color)
    .active-route
      .name
        background-color: var(--text-color)
        color: #fff
        .icon
          background-color: var(--white-color)
      .inner-links
        .name
          background-color: #fff
          color: var(--text-color)
        .router-link-active, .inner-inner-links .router-link-active
          .name
            background-color: var(--text-color)
            color: #fff
    .main-link:not(.active-route)
      .name
        overflow: hidden
        position: relative
        &::before
          content: ''
          background-color: var(--text-color)
          width: 8px
          height: 100%
          position: absolute
          left: -8px
          transition: .3s ease
        &:hover
          &::before
            left: 0
@media only screen and (min-width: 1201px)
  .first-slide
    border-right: 0 solid var(--gray-lines-color)
    background: linear-gradient(90deg, rgba(#0D1F61,1) 0%, rgba(#0D1F61,0.85) 15%, rgba(#0D1F61,0.7) 30%, rgba(#0D1F61,0.5) 50%, rgba(#0D1F61,0.4) 60%, rgba(#0D1F61,0.25) 75%, rgba(13,31,97,0) 100%)
    transition: .3s ease-out
    .main
      .logo-wrapper
        border-bottom: 0 solid var(--gray-lines-color)
        transition: .3s ease-out
@media only screen and (max-width: $bp-pc)
  .opened-mobile
    background-color: #fff
  .header
    width: 100%
    border-right: none
    height: 100px
    padding: 30px 0
    display: grid
    background-color: #fff
    grid-template-columns: 1fr 1fr
    .main
      border-bottom: none
      .logo-wrapper
        padding: 0
        margin: 0 65px
        width: 30%
    .overlay
      display: none
    .search-comp
      position: absolute
      top: 100%
      z-index: 1
      width: 100%
      padding: 10px 35px 0
      background: var(--white-color)
    .nav
      position: absolute
      top: calc(100% + 47px)
      width: 100%
      height: calc(100svh - 100px - 47px)
      overflow-y: auto
      grid-column: 1/3
      background-color: #fff
      border: 1px solid var(--gray-lines-color)
      padding: 35px 65px 50px
      .nav-link
        display: grid
        grid-template-columns: 1fr 1fr
        gap: 65px * 2
        width: 100%
        border-left: 1px solid var(--gray-lines-color)
      .name
        border-bottom: 1px solid var(--gray-lines-color)
        border-right: 1px solid var(--gray-lines-color)
        padding: 16px 30px
        font-size: 0.8125rem
        &:first-child
          border-top: 1px solid var(--gray-lines-color)
      .inner-links
        left: calc(50% - 65px)
        height: calc(100% + 130px)
        top: 0
        .nav-btns .active-route
          .name
            background-color: #fff
            color: #778497 !important
        .inner-header
          height: 130px
          min-height: 130px
          .title
            font-size: 1.5rem
        .name
          padding: 16px 30px
          font-size: 0.8125rem
        .inner-inner-links
          .name
            padding: 16px 30px
            font-size: 0.8125rem
      .lang-switcher
        width: calc(50% - 65px)
        display: flex
        justify-content: center
        align-items: center
@media only screen and (max-width: $bp-tablet-landscape-up)
  .header
    height: 100px
    .logo-wrapper
      margin: 0 45px
      width: 40%
    .search-comp
      padding: 10px 35px 0
    .nav
      padding: 35px 45px 80px
      height: calc(100svh - 90px)
      .lang-switcher
        align-self: center
        border-right: none
      .nav-link
        display: flex
        flex-direction: column
        height: auto
        gap: 0
      .name
        font-size: 1rem
        padding: 22px 30px
      .inner-links
        position: relative
        left: unset
        top: unset
        height: auto
        width: 100%
        .nav-btns .active-route
          .name
            background-color: #f8f9fd
        .inner-header
          display: none
        .link-opened::before
          display: none
        .name
          background-color: #f8f9fd
          font-size: 0.875rem
          padding: 22px 30px
        .inner-inner-links
          .name
            font-size: 0.875rem
            padding: 22px 30px
            background-color: #EDF2F8
@media only screen and (max-width: $bp-tablet)
  .header
    height: 70px
    padding: 20px 0
    .main
      .logo-wrapper
        margin: 0 17px
        width: auto
        height: 100%
        img
          max-height: 30px
    .search-comp
      position: absolute
      top: 100%
      z-index: 1
      width: 100%
      padding: 10px 22px 0
      background: var(--white-color)
    .nav
      padding: 30px 0 50px
      height: calc(100svh - 70px)
      .name
        font-size: 1rem
        padding: 17px
      .inner-links
        .name
          background-color: #f8f9fd
          font-size: 0.875rem
          padding: 17px
        .inner-inner-links
          .name
            font-size: 0.875rem
            padding: 17px
            background-color: #EDF2F8
</style>
